<script lang="ts">
  import { onMount } from "svelte";
  interface Props {
    value?: string;
    squareSize?: number;
  }

  let { value = "", squareSize = 200 }: Props = $props();

  let qrcode;

  onMount(async () => {
    let script = document.createElement("script");
    script.src =
      "https://cdn.rawgit.com/davidshimjs/qrcodejs/gh-pages/qrcode.min.js";
    document.head.append(script);

    script.onload = function () {
      //@ts-ignore
      qrcode = new QRCode("qrcode", {
        text: value,
        width: squareSize,
        height: squareSize,
        colorDark: "#000000",
        colorLight: "#ffffff",
        //@ts-ignore
        correctLevel: QRCode.CorrectLevel.H,
      });
    };
  });
</script>

<div id="qrcode"></div>
