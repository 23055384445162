<script lang="ts">
  import type { HTMLAttributes } from "svelte/elements";
  import { cn } from "@/helpers/tw-utils.js";

  type $$Props = HTMLAttributes<HTMLDivElement>;

  interface Props {
    class?: $$Props["class"];
    children?: import('svelte').Snippet;
    [key: string]: any
  }

  let { class: className = undefined, children, ...rest }: Props = $props();
  
</script>

<div class={cn("text-sm [&_p]:leading-relaxed", className)} {...rest}>
  {@render children?.()}
</div>
